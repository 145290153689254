import { Component } from 'react';
import PropTypes from 'prop-types';

// some icons are rendered in color so require inline SVGs
export default class FlatIconSVG extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    render() {
        switch (this.props.name) {
            case 'pinterest':
                return (
                    <svg id="pinterest" viewBox="0 0 511.977 511.977"><title>pinterest</title>
                        <path style={{"fill": "#D32F2F"}}
                              d="M262.948,0C122.628,0,48.004,89.92,48.004,187.968c0,45.472,25.408,102.176,66.08,120.16 c6.176,2.784,9.536,1.6,10.912-4.128c1.216-4.352,6.56-25.312,9.152-35.2c0.8-3.168,0.384-5.92-2.176-8.896 c-13.504-15.616-24.224-44.064-24.224-70.752c0-68.384,54.368-134.784,146.88-134.784c80,0,135.968,51.968,135.968,126.304 c0,84-44.448,142.112-102.208,142.112c-31.968,0-55.776-25.088-48.224-56.128c9.12-36.96,27.008-76.704,27.008-103.36 c0-23.904-13.504-43.68-41.088-43.68c-32.544,0-58.944,32.224-58.944,75.488c0,27.488,9.728,46.048,9.728,46.048 S144.676,371.2,138.692,395.488c-10.112,41.12,1.376,107.712,2.368,113.44c0.608,3.168,4.16,4.16,6.144,1.568 c3.168-4.16,42.08-59.68,52.992-99.808c3.968-14.624,20.256-73.92,20.256-73.92c10.72,19.36,41.664,35.584,74.624,35.584 c98.048,0,168.896-86.176,168.896-193.12C463.62,76.704,375.876,0,262.948,0"/>
                    </svg>
                );
            case 'facebook':
                return (
                    <svg id="facebook-2" viewBox="0 0 408.788 408.788"><title>facebook-2</title>
                        <path style={{"fill": "#475993"}}
                              d="M353.701,0H55.087C24.665,0,0.002,24.662,0.002,55.085v298.616c0,30.423,24.662,55.085,55.085,55.085 h147.275l0.251-146.078h-37.951c-4.932,0-8.935-3.988-8.954-8.92l-0.182-47.087c-0.019-4.959,3.996-8.989,8.955-8.989h37.882 v-45.498c0-52.8,32.247-81.55,79.348-81.55h38.65c4.945,0,8.955,4.009,8.955,8.955v39.704c0,4.944-4.007,8.952-8.95,8.955 l-23.719,0.011c-25.615,0-30.575,12.172-30.575,30.035v39.389h56.285c5.363,0,9.524,4.683,8.892,10.009l-5.581,47.087 c-0.534,4.506-4.355,7.901-8.892,7.901h-50.453l-0.251,146.078h87.631c30.422,0,55.084-24.662,55.084-55.084V55.085 C408.786,24.662,384.124,0,353.701,0z"/>
                    </svg>
                );
            case 'youtube':
                return (
                    <svg id="youtube-4" viewBox="0 0 512 512"><title>youtube-4</title>
                        <path style={{"fill": "#D8362A"}}
                              d="M506.703,145.655c0,0-5.297-37.959-20.303-54.731c-19.421-22.069-41.49-22.069-51.2-22.952 C363.697,62.676,256,61.793,256,61.793l0,0c0,0-107.697,0.883-179.2,6.179c-9.71,0.883-31.779,1.766-51.2,22.952 C9.71,107.697,5.297,145.655,5.297,145.655S0,190.676,0,235.697v41.49c0,45.021,5.297,89.159,5.297,89.159 s5.297,37.959,20.303,54.731c19.421,22.069,45.021,21.186,56.497,23.835C122.703,449.324,256,450.207,256,450.207 s107.697,0,179.2-6.179c9.71-0.883,31.779-1.766,51.2-22.952c15.007-16.772,20.303-54.731,20.303-54.731S512,321.324,512,277.186 v-41.49C512,190.676,506.703,145.655,506.703,145.655"/>
                        <polygon style={{"fill": "#FFFFFF"}} points="194.207,166.841 194.207,358.4 361.931,264.828 "/>
                    </svg>
                );
            case 'vimeo':
                return (
                    <svg id="vimeo" viewBox="0 0 434.016 434.016"><title>vimeo</title>
                        <path style={{"fill": "#69B4E5"}}
                              d="M416.049,52.27c-10.508-11.324-25.72-17.356-45.207-17.928 c-55.02-1.774-93.932,27.496-115.651,86.986c-1.14,3.124-0.444,6.588,1.814,9.041c2.247,2.439,5.622,3.409,8.812,2.534 c6.246-1.718,12.44-2.588,18.412-2.588c8.018,0,18.689,1.474,24.001,8.497c4.077,5.39,4.632,13.472,1.649,24.02 c-2.518,8.899-15.476,32.721-31.515,57.929c-19.539,30.712-30.333,44.48-33.445,44.48c-10.31,0-47.565-165.194-48.589-168.817 c-10.686-37.86-16.05-56.864-50.798-56.864c-26.483,0-85.551,48.362-134.051,90.495c-2.983,2.591-5.495,4.774-7.44,6.436 c-4.688,4.008-5.395,10.97-1.607,15.849l9.038,11.644c3.807,4.906,10.751,5.93,15.812,2.331l0.497-0.354 c15.691-11.163,30.514-21.706,41.704-22.284c12.16-0.597,22.711,18.4,35.29,63.605c23.957,87.803,60.355,192.468,97.709,192.468 c39.036,0,85.605-33.091,145.802-111.429c54.482-70.902,83.655-127.135,85.529-168.048l0,0 C435.222,89.372,429.245,66.493,416.049,52.27z"/>
                    </svg>
                );
            case 'twitter':
                return (
                    <svg id="twitter-2" viewBox="0 0 410.155 410.155"><title>twitter-2</title>
                        <path style={{"fill": "#76A9EA"}}
                              d="M403.632,74.18c-9.113,4.041-18.573,7.229-28.28,9.537c10.696-10.164,18.738-22.877,23.275-37.067 l0,0c1.295-4.051-3.105-7.554-6.763-5.385l0,0c-13.504,8.01-28.05,14.019-43.235,17.862c-0.881,0.223-1.79,0.336-2.702,0.336 c-2.766,0-5.455-1.027-7.57-2.891c-16.156-14.239-36.935-22.081-58.508-22.081c-9.335,0-18.76,1.455-28.014,4.325 c-28.672,8.893-50.795,32.544-57.736,61.724c-2.604,10.945-3.309,21.9-2.097,32.56c0.139,1.225-0.44,2.08-0.797,2.481 c-0.627,0.703-1.516,1.106-2.439,1.106c-0.103,0-0.209-0.005-0.314-0.015c-62.762-5.831-119.358-36.068-159.363-85.14l0,0 c-2.04-2.503-5.952-2.196-7.578,0.593l0,0C13.677,65.565,9.537,80.937,9.537,96.579c0,23.972,9.631,46.563,26.36,63.032 c-7.035-1.668-13.844-4.295-20.169-7.808l0,0c-3.06-1.7-6.825,0.485-6.868,3.985l0,0c-0.438,35.612,20.412,67.3,51.646,81.569 c-0.629,0.015-1.258,0.022-1.888,0.022c-4.951,0-9.964-0.478-14.898-1.421l0,0c-3.446-0.658-6.341,2.611-5.271,5.952l0,0 c10.138,31.651,37.39,54.981,70.002,60.278c-27.066,18.169-58.585,27.753-91.39,27.753l-10.227-0.006 c-3.151,0-5.816,2.054-6.619,5.106c-0.791,3.006,0.666,6.177,3.353,7.74c36.966,21.513,79.131,32.883,121.955,32.883 c37.485,0,72.549-7.439,104.219-22.109c29.033-13.449,54.689-32.674,76.255-57.141c20.09-22.792,35.8-49.103,46.692-78.201 c10.383-27.737,15.871-57.333,15.871-85.589v-1.346c-0.001-4.537,2.051-8.806,5.631-11.712c13.585-11.03,25.415-24.014,35.16-38.591 l0,0C411.924,77.126,407.866,72.302,403.632,74.18L403.632,74.18z"/>
                    </svg>
                );
            case 'drive':
                return (
                    <svg id="google-drive" viewBox="0 0 512 512"><title>google-drive</title>
                        <polygon style={{"fill": "#3089F3"}}
                                 points="512,336.842 155.396,336.842 80.842,480.561 431.158,480.561 "/>
                        <polygon style={{"fill": "#00A76A"}}
                                 points="170.667,31.439 0,318.877 80.842,480.561 245.221,164.379 "/>
                        <polygon style={{"fill": "#FDD446"}}
                                 points="332.351,31.439 170.667,31.439 341.333,336.842 512,336.842 "/>
                    </svg>
                );
            case 'ical':
                return (
                    <svg id="calendar-4" viewBox="0 0 511.999 511.999"><title>calendar-4</title>
                        <path style={{"fill": "#E6E6E6"}}
                              d="M455.617,10.846H56.367c-12.437,0-22.517,10.094-22.517,22.531v74.912h444.296V33.377 C478.147,20.939,468.053,10.846,455.617,10.846z M148.16,55.922c-5.953,0-10.784-4.831-10.784-10.784s4.831-10.784,10.784-10.784 c5.953,0,10.784,4.831,10.784,10.784S154.112,55.922,148.16,55.922z M366.656,55.922c-5.953,0-10.784-4.831-10.784-10.784 s4.831-10.784,10.784-10.784s10.784,4.831,10.784,10.784S372.608,55.922,366.656,55.922z"/>
                        <path style={{"fill": "#3A5BBC"}}
                              d="M511.505,113.523l-34.077,177.704l13.918,184.793c1.021,13.559-9.706,25.134-23.322,25.134H43.96 c-13.602,0-24.343-11.575-23.322-25.134l13.933-184.793L0.494,113.523C-2.712,96.815,10.099,81.3,27.123,81.3h457.74 C501.901,81.3,514.711,96.815,511.505,113.523z"/>
                        <path style={{"fill": "#518EF8"}}
                              d="M468.024,501.153H43.96c-13.602,0-24.343-11.575-23.322-25.134l13.933-184.793h244.435h198.423 l13.918,184.793C492.368,489.578,481.641,501.153,468.024,501.153z"/>
                        <g>
                            <path style={{"fill": "#FFFFFF"}}
                                  d="M247.371,244.856c0-34.687-29.026-62.906-64.703-62.906s-64.703,28.219-64.703,62.906h21.568 c0-22.794,19.351-41.338,43.136-41.338s43.136,18.544,43.136,41.338s-19.351,41.338-43.136,41.338h-20.13v21.568h20.13 c23.785,0,43.136,18.544,43.136,41.338c0,22.794-19.351,41.338-43.136,41.338s-43.136-18.544-43.136-41.338h-21.568 c0,34.687,29.026,62.906,64.703,62.906s64.703-28.219,64.703-62.906c0-21.663-11.322-40.802-28.513-52.122 C236.05,285.659,247.371,266.519,247.371,244.856z"/>
                            <polygon style={{"fill": "#FFFFFF"}}
                                     points="289.289,218.704 300.353,237.218 342.989,211.741 342.989,401.222 364.556,401.222 364.556,173.728 "/>
                        </g>
                    </svg>
                )
            case 'office':
                return (
                    <svg id="office" viewBox="0 0 512 512"><title>office</title>
                        <polygon style={{"fill": "#FF5722"}}
                                 points="320,0 32,112 32,416 128,384 128,128 320,96 320,449.408 32,416 320,512 480,464 480,48 "/>
                    </svg>
                )
            case 'google-plus':
                return (
                    <svg id="google-plus-1" viewBox="0 0 512 512"><title>google-plus-1</title>
                        <polygon style={{"fill": "#F44336"}}
                                 points="512,224 448,224 448,160 416,160 416,224 352,224 352,256 416,256 416,320 448,320 448,256 512,256 "/>
                        <path style={{"fill": "#F44336"}}
                              d="M160,288h90.528c-13.216,37.248-48.8,64-90.528,64c-52.928,0-96-43.072-96-96s43.072-96,96-96 c22.944,0,45.024,8.224,62.176,23.168l42.048-48.256C235.424,109.824,198.432,96,160,96C71.776,96,0,167.776,0,256 s71.776,160,160,160s160-71.776,160-160v-32H160V288z"/>
                    </svg>
                );
            case 'docs':
                return (
                    <svg id="docs" viewBox="0 0 512 512"><title>docs</title>
                        <path style={{"fill": "#518EF8"}}
                              d="M441.412,140.235v338.781c0,18.219-14.778,32.983-32.983,32.983H103.572 c-18.219,0-32.983-14.764-32.983-32.983V32.983C70.588,14.764,85.352,0,103.572,0h197.605L441.412,140.235z"/>
                        <g>
                            <rect x="161.18" y="257.054" style={{"fill": "#FFFFFF"}} width="189.626" height="21.071"/>
                            <rect x="161.18" y="304.112" style={{"fill": "#FFFFFF"}} width="189.626" height="21.071"/>
                            <rect x="161.18" y="351.171" style={{"fill": "#FFFFFF"}} width="189.626" height="21.071"/>
                            <rect x="161.18" y="398.23" style={{"fill": "#FFFFFF"}} width="134.855" height="21.071"/>
                        </g>
                        <polygon style={{"fill": "#3A5BBC"}}
                                 points="320.31,137.188 441.412,187.079 441.412,140.235 372.751,119.962 "/>
                        <path style={{"fill": "#ACD1FC"}}
                              d="M441.412,140.235H334.16c-18.22,0-32.983-14.764-32.983-32.983V0L441.412,140.235z"/>
                    </svg>
                );
            case 'sheets':
                return (
                    <svg id="sheets" viewBox="0 0 512 512"><title>sheets</title>
                        <path style={{"fill": "#28B446"}}
                              d="M441.412,140.235v338.781c0,18.219-14.778,32.983-32.983,32.983H103.572 c-18.219,0-32.983-14.764-32.983-32.983V32.983C70.588,14.764,85.352,0,103.572,0h197.605L441.412,140.235z"/>
                        <polygon style={{"fill": "#219B38"}}
                                 points="320.31,137.188 441.412,187.079 441.412,140.235 372.751,119.962 "/>
                        <path style={{"fill": "#6ACE7C"}}
                              d="M441.412,140.235H334.16c-18.22,0-32.983-14.764-32.983-32.983V0L441.412,140.235z"/>
                        <path style={{"fill": "#FFFFFF"}}
                              d="M337.115,254.946H174.876c-5.82,0-10.536,4.717-10.536,10.536v141.169 c0,5.818,4.716,10.536,10.536,10.536h162.239c5.82,0,10.536-4.717,10.536-10.536V265.482 C347.651,259.664,342.935,254.946,337.115,254.946z M185.412,322.682h60.048v26.773h-60.048V322.682z M266.531,322.682h60.048 v26.773h-60.048V322.682z M326.58,301.611h-60.048v-25.593h60.048L326.58,301.611L326.58,301.611z M245.46,276.018v25.592h-60.048 v-25.592H245.46z M185.412,370.526h60.048v25.592h-60.048V370.526z M266.531,396.117v-25.592h60.048v25.592H266.531z"/>
                    </svg>
                )
            case 'instagram':
                return (
                    <svg id="instagram-2" viewBox="0 0 551.034 551.034"><title>instagram-2</title>
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517"
                                        y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                            <stop offset="0" style={{"stopColor": "#E09B3D"}}/>
                            <stop offset="0.3" style={{"stopColor": "#C74C4D"}}/>
                            <stop offset="0.6" style={{"stopColor": "#C21975"}}/>
                            <stop offset="1" style={{"stopColor": "#7024C4"}}/>
                        </linearGradient>
                        <path style={{"fill": "url(#SVGID_1_)"}}
                              d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"/>
                        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517"
                                        y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                            <stop offset="0" style={{"stopColor": "#E09B3D"}}/>
                            <stop offset="0.3" style={{"stopColor": "#C74C4D"}}/>
                            <stop offset="0.6" style={{"stopColor": "#C21975"}}/>
                            <stop offset="1" style={{"stopColor": "#7024C4"}}/>
                        </linearGradient>
                        <path style={{"fill": "url(#SVGID_2_)"}}
                              d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517 S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083 s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"/>
                        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="418.31" y1="4.57" x2="418.31"
                                        y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                            <stop offset="0" style={{"stopColor": "#E09B3D"}}/>
                            <stop offset="0.3" style={{"stopColor": "#C74C4D"}}/>
                            <stop offset="0.6" style={{"stopColor": "#C21975"}}/>
                            <stop offset="1" style={{"stopColor": "#7024C4"}}/>
                        </linearGradient>
                        <circle style={{"fill": "url(#SVGID_3_)"}} cx="418.31" cy="134.07" r="34.15"/>
                    </svg>
                )
            case 'rss':
                return <svg xmlns="http://www.w3.org/2000/svg"
                            id="RSSicon"
                            viewBox="0 0 8 8" width="256" height="256">
                    <title>RSS feed icon</title>
                    <rect style={{"stroke": "none", "fill": "#f26522"}} width="8" height="8" rx="1.5"/>
                    <circle style={{"stroke": "none", "fill": "white"}} cx="2" cy="6" r="1"/>
                    <path style={{"stroke": "none", "fill": "white"}}
                          d="m 1,4 a 3,3 0 0 1 3,3 h 1 a 4,4 0 0 0 -4,-4 z"/>
                    <path style={{"stroke": "none", "fill": "white"}}
                          d="m 1,2 a 5,5 0 0 1 5,5 h 1 a 6,6 0 0 0 -6,-6 z"/>

                </svg>;
            case 'yelp':
                return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 435.646 435.646" style={{"enable-background": "new 0 0 435.646 435.646"}}>
                    <g>
                        <path style={{"fill": "#DC0000"}} d="M182.47,306.937l-60.235,73.351c-8.475,10.32-4.426,25.905,8,30.796l58.889,23.178
		c12.704,5,26.514-4.141,26.875-17.789l2.539-96.083C219.05,301.021,194.766,291.964,182.47,306.937z"/>
                        <path style={{"fill": "#DC0000"}} d="M172.459,234.019l-89.331-32.073c-12.569-4.513-25.886,4.539-26.315,17.887l-2.033,63.253
		c-0.439,13.646,12.813,23.58,25.788,19.331l91.345-29.907C190.327,266.482,190.694,240.566,172.459,234.019z"/>
                        <path style={{"fill": "#DC0000"}} d="M274.839,244.311l91.502-25.22c12.874-3.548,18.549-18.617,11.214-29.777l-34.762-52.883
		c-7.499-11.408-24.054-11.899-32.215-0.954l-57.458,77.052C241.537,228.061,256.161,249.46,274.839,244.311z"/>
                        <path style={{"fill": "#DC0000"}} d="M208.574,24.431c0.728-15.826-14.185-27.765-29.469-23.591l-72.428,19.78
		c-15.625,4.267-22.261,22.761-12.914,35.989l83.116,143.998c0.675,1.169,1.419,2.302,2.294,3.331
		c12.309,14.465,35.417,9.105,41.113-7.828c1.023-3.042,1.311-6.284,1.059-9.484L208.574,24.431z"/>
                        <path style={{"fill": "#DC0000"}} d="M366.966,310.135l-91.883-28.213c-18.521-5.687-33.759,15.279-22.631,31.14l54.511,77.7
		c7.669,10.932,23.77,11.211,31.813,0.551l38.12-50.516C385.12,329.899,380.017,314.143,366.966,310.135z"/>
                    </g>
                </svg>;
            case 'google':
                return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 512 512" style={{"enable-background": "new 0 0 512 512"}}>
                    <path style={{"fill": "#FBBB00"}} d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256
	c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456
	C103.821,274.792,107.225,292.797,113.47,309.408z"/>
                    <path style={{"fill": "#518EF8"}} d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451
	c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535
	c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"/>
                    <path style={{"fill": "#28B446"}} d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512
	c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771
	c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"/>
                    <path style={{"fill": "#F14336"}} d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012
	c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0
	C318.115,0,375.068,22.126,419.404,58.936z"/>
                </svg>
        }
        return null;
    }
}
